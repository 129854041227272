
import ArticleService from '@/services/ArticleService';
import CatalogService from '@/services/CatalogService';
import PageService from '@/services/PageService';
import Article from '@/entities/blog/Article';
import Catalog from '@/entities/catalog/Catalog';
import Page from '@/entities/page/Page';

export interface ComponentData {
    deprecated: string[];
    homepage: Page | null;
    pages: Page[];
    catalogs: Catalog[];
    articles: Article[];
}

export default {
    name: 'TestForms',
    components: {},
    data(): ComponentData {
        return {
            deprecated: [
                'SectionBenefitStyle1',
                'SectionBenefitStyle2',
                'SectionBenefitStyle3',
                'SectionBenefitStyle4',
                'SectionBenefitStyle5',
                'SectionFAQStyle1',
                'SectionFAQStyle2',
                'SectionPropertiesStyle1',
                'SectionPropertiesStyle2',
                'SectionPropertiesStyle3',
                'SectionPropertiesStyle4',
                'SectionTestimonialStyle1',
                'SectionCategoryStyle1',
                'SectionCategoryStyle2',
                'SectionCategoryStyle3',
                'SectionCategoryStyle4',
                'SectionCategoryStyle5',
                'SectionCategoryStyle6',
                'SectionCategoryStyle7',
                'SectionCategoryStyle8',
            ],
            homepage: null,
            pages: [],
            catalogs: [],
            articles: [],
        };
    },
    computed: {},
    async created() {
        const params = { limit: 100 };

        const [errorHome, resultHome] = await PageService.getHomepage();
        if (errorHome) errorHome.alert();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (resultHome !== null) this.homepage = resultHome;

        const [errorPages, resultPages] = await PageService.getAll(params);
        if (errorPages) errorPages.alert();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (resultPages !== null) this.pages = resultPages;

        const [errorArticles, resulArticles] = await ArticleService.getAll(params);
        if (errorArticles) errorArticles.alert();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (resulArticles !== null) this.articles = resulArticles;

        const [errorCatalogs, resultCatalogs] = await CatalogService.getAll(params);
        if (errorCatalogs) errorCatalogs.alert();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (resultCatalogs !== null) this.catalogs = resultCatalogs;
    },
    methods: {
        onSubmit() {
            console.log('onSubmit');
        },
    },
};
